import React from "react";
import oeiruty from '../media/hobspot-loader.webp';

const Loader = ()=>{
    return(<div style={{
        overflow:'hidden'
    }}>
            {/* <img 
                alt="loader"
                src={oeiruty}
                style={{
                    width:'100vw',
                    heigh:'100vh',
                }}
            /> */}

            <p className="loaddding" style={{
                color:'#aaa',
                textAlign:'center',
                marginTop:'4em',
                fontSize:'23px',
                // fontWeight:'bold',
                fontFamily:'monospace'
            }}>Loading...</p>
    </div>)
};

export default Loader;